import { TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    fontFamily: "'Zain', san-serif",
    fontSize: 25,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 400,
  },
  "& .MuiInputLabel-shrink": {
    fontFamily: "'Zain', san-serif",
    fontSize: 20,
    marginTop: -2,
    fontWeight: 400,
  },
  "& .MuiOutlinedInput-root": {
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

export const TextField = ({ children, ...props }) => {
  return <CustomTextField {...props}>{children}</CustomTextField>;
};
