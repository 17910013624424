import { CardHeader as MuiCardHeader } from "@mui/material";
import { styled } from "@mui/material/styles";


export const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  fontFamily:  "'Zain', san-serif",
  '& .MuiCardHeader-content':{
    display: "flex",
  },
  '& .MuiCardHeader-content .MuiCardHeader-title':{
    fontFamily: "'Zain', san-serif",
    fontSize: 24,
    background: theme.palette.primary.main,
    color: "#FFFFFF", 
    borderRadius: 40,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: -35,
  }
}));
