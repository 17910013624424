import { TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTextFieldAmount = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    fontFamily: "'Zain', san-serif",
    fontSize: 30,
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: 800,
  },
  "& .MuiInputLabel-shrink": {
    fontFamily: "'Zain', san-serif",
    fontSize: 20,
    marginTop: -2,
    fontWeight: 600,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 50,
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

export const TextFieldAmount = ({ children, ...props }) => {
  return <CustomTextFieldAmount {...props}>{children}</CustomTextFieldAmount>;
};
