import axios from "axios";

const url = "https://bilhete-app-api.onrender.com/checkout/";
const urlTransactions = "https://bilhete-app-api.onrender.com/transactions";

export async function getCheckoutFromAPI(id) {
  try {
    const response = await axios.get(`${url}${id}`);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error.message;
  }
}
export async function getTransactionsFromAPI(number, page, pageSize, search) {
  try {
    const response = await axios.get(
      `${urlTransactions}/${number}/${page}/${pageSize}/${search}`
    );
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error.message;
  }
}

export async function getCheckoutStatusFromAPI(id) {
  try {
    const response = await axios.get(`${url}status/${id}`);
    return response.data.data;
  } catch (error) {
    return error.response ? error.response.data : error.message;
  }
}
