import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomButton = styled(MuiButton)(({ theme }) => ({
  borderRadius: 100,
  fontFamily: "'Zain', san-serif",
  fontWeight: 800,
  "& .MuiButton-label-primary": {
    paddingTop: "4px",
    fontSize: 20,
  },
  "& .MuiButton-label-small": {
    paddingTop: "2px",
    fontSize: 14,
    marginLeft: -3,
  },
}));

export const Button = ({ children, ...props }) => {
  return <CustomButton {...props}>{children}</CustomButton>;
};
