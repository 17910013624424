import {
  Facebook,
  Instagram,
  Link as LinkIcon,
  WhatsApp,
} from "@mui/icons-material";
import { Button } from "../Button";
import { FaTiktok } from "react-icons/fa6";
import { Link } from "react-router-dom";

const NetworkIcon = (type) => {
  let icon;
  switch (type) {
    case "WHATSAPP_GROUP":
      icon = <WhatsApp />;
      break;
    case "WHATSAPP_PHONE":
      icon = <WhatsApp />;
      break;
    case "INSTAGRAM":
      icon = <Instagram />;
      break;
    case "FACEBOOK":
      icon = <Facebook />;
      break;
    case "TIKTOK":
      icon = <FaTiktok />;
      break;
    case "OTHERS":
      icon = <LinkIcon />;
      break;
    default:
      icon = <LinkIcon />;
      break;
  }
  return icon;
};

const NameIcon = (type) => {
  let icon;
  switch (type) {
    case "WHATSAPP_GROUP":
      icon = "Grupo Whatsapp";
      break;
    case "WHATSAPP_PHONE":
      icon = "Whatsapp";
      break;
    case "INSTAGRAM":
      icon = "Instagram";
      break;
    case "FACEBOOK":
      icon = "Facebook";
      break;
    case "TIKTOK":
      icon = "Tiktok";
      break;
    case "OTHERS":
      icon = "Site";
      break;
    default:
      icon = "Site";
      break;
  }
  return icon;
};

export const NetworksButton = ({ type, url }) => {
  return (
    <Link to={url} target="_blank">
      <Button startIcon={NetworkIcon(type)} variant="outlined" size="small">
        <span className="MuiButton-label-small">{NameIcon(type)}</span>
      </Button>
    </Link>
  );
};
