import { Typography as MuiTypograph } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomTypography = styled(MuiTypograph)(({ theme }) => ({
  fontFamily: "'Zain', san-serif",
  "& .MuiTypography-div": {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important",
  },
}));

export const Typography = ({ children, ...props }) => {
  return <CustomTypography {...props}>{children}</CustomTypography>;
};
