import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  useThemeProps,
  useTheme,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import ReactElasticCarousel from "react-elastic-carousel";
import { useParams } from "react-router-dom";
import "./App.css";
import slideImgMock from "./assets/imgs/test.jpg";
import { getRifaFromAPI } from "./hooks/RifaPage/useRifaPage";
import noImgage from "./assets/imgs/noimage.png";
import {
  Add,
  Adjust,
  Circle,
  Close,
  ConfirmationNumber,
  Instagram,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Remove,
  WhatsApp,
} from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import { Button } from "./components/Button";
import CheckoutModal from "./components/CheckoutModal";
import HeaderMenu from "./components/HeaderMenu";
import DOMPurify from "dompurify";
import { Typography } from "./components/Typography";
import { CardContent } from "./components/CardContent";
import { CardHeader } from "./components/CardHeader";
import { Card } from "./components/Card";
import { TextField } from "./components/TextField";
import { Footer } from "./components/Footer";
import { TextFieldAmount } from "./components/TextFieldAmount";
import { NetworksButton } from "./components/NetowroksButton";

const useStyles = makeStyles({
  visible: {
    "&:hover": {
      color: "#27b279",
    },
  },
  arrowsBoxLeft: {
    position: "absolute",
    height: "100%",
    zIndex: 1,
    "&:hover": {
      color: "#FFFFFF",
      cursor: "pointer",
      zIndex: 1,
    },
  },
  arrowsBoxRight: {
    position: "absolute",
    height: "100%",
    zIndex: 1,
    right: 0,
    "&:hover": {
      color: "#FFFFFF",
      cursor: "pointer",
      zIndex: 1,
    },
  },
  arrowsLeft: {
    position: "relative",
    zIndex: 1,
    color: "#FFFFFF !important",
    top: "40%",
    "&:hover": {
      color: "#FFFFFF",
      cursor: "pointer",
    },
  },
  arrowsRight: {
    position: "relative",
    zIndex: 1,
    color: "#FFFFFF !important",
    marginLeft: -95,
    top: "40%",
    "&:hover": {
      color: "#27b279",
      cursor: "pointer",
    },
  },
});

function Rifa() {
  const { username, uuid } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const [buyTicket, setBuyTicket] = useState(false);

  const [dataRifa, setDataRifa] = useState({});
  const [itemsToShow, setItemsToShow] = useState(2);
  const [loading, setLoading] = useState(true);

  const [modalCheckout, setModalCheckout] = useState(false);
  const [isDisabledButton, setDisabledButton] = useState(false);

  const setModalsetBuyTicket = (value) => {
    setDisabledButton(true);
    setBuyTicket(value);
    setTimeout(() => {
      setDisabledButton(false);
    }, 1000);
  };

  const [dataForm, setDataForm] = useState({
    amount: 1,
    custumer_phone: undefined,
    user: undefined,
    unitValue: 0,
    value: 0,
    ticket_id: "",
  });
  console.log({
    username,
    uuid,
  });
  const getRifa = () => {
    getRifaFromAPI(username, uuid)
      .then((res) => {
        if (res) setDataRifa(res);
        setDataForm({
          ...dataForm,
          value: Number(res?.data?.ticket?.price),
          unitValue: res?.data?.ticket?.price,
          user: res?.data?.ticket?.user,
          ticket_id: res?.data?.ticket?.id,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
  };

  useEffect(() => {
    getRifa();
  }, []);

  useEffect(() => {
    const updateItemsToShow = () => {
      const width = window.innerWidth;
      if (width < 600) {
        setItemsToShow(1);
      } else if (width < 900) {
        setItemsToShow(2);
      } else if (width < 1200) {
        setItemsToShow(3);
      } else {
        setItemsToShow(4);
      }
    };

    updateItemsToShow(); // Chama a função na montagem do componente

    window.addEventListener("resize", updateItemsToShow); // Adiciona um listener para o resize da tela

    return () => window.removeEventListener("resize", updateItemsToShow); // Limpa o listener ao desmontar o componente
  }, []);

  const updateTicketsNumber = (type, value, actual) => {
    switch (type) {
      case "ADD":
        if (actual >= dataRifa?.data?.ticket?.total_tickets) {
          const sumPrice = dataForm.unitValue * actual;
          setDataForm({
            ...dataForm,
            amount: dataRifa?.data?.ticket?.total_tickets,
            value: sumPrice,
          });
        } else {
          const sum = actual + value;
          const sumPrice = dataForm.unitValue * sum;
          if (sum >= dataRifa?.data?.ticket?.total_tickets) {
            const sumPrice =
              dataForm.unitValue * dataRifa?.data?.ticket?.total_tickets;
            setDataForm({
              ...dataForm,
              amount: dataRifa?.data?.ticket?.total_tickets,
              value: sumPrice,
            });
          } else {
            setDataForm({ ...dataForm, amount: sum, value: sumPrice });
          }
        }

        break;
      case "REMOVE":
        if (actual <= 1) {
          const subPrice = dataForm.unitValue * actual;
          setDataForm({ ...dataForm, amount: 1, value: subPrice });
        } else {
          const sub = actual - value;
          const subPrice = dataForm.unitValue * sub;
          console.log(sub);
          setDataForm({ ...dataForm, amount: sub, value: subPrice });
        }
        break;

      default:
        break;
    }
  };

  const handleChange = (value) => {
    setDataForm({ ...dataForm, custumer_phone: value });
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    const amount = Number(value);

    if (amount <= 0) {
      const sumPrice = dataForm.unitValue * 1;
      setDataForm({
        ...dataForm,
        amount: 1,
        value: sumPrice,
      });
    } else {
      const maxTickets = dataRifa?.data?.ticket?.initial_total_tickets || 0;
      const sumPrice =
        dataForm.unitValue * Number(amount >= maxTickets ? maxTickets : amount);
      setDataForm({
        ...dataForm,
        amount: amount >= maxTickets ? maxTickets : amount,
        value: sumPrice,
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={(theme) => ({
          background: "#ffffff4d",
          zIndex: theme.zIndex.drawer + 99999,
          backdropFilter: "blur(10px)",
        })}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          background: "#ffffff4d",
          zIndex: theme.zIndex.drawer + 99999,
          backdropFilter: "blur(10px)",
        })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CheckoutModal
        open={modalCheckout}
        handleClose={() => setModalCheckout(false)}
        formData={dataForm}
        handleChange={handleChange}
      />
      <Grid container direction="row" justifyContent="center">
        <HeaderMenu />
        <Grid item xs="12">
          <ReactElasticCarousel
            isRTL={false}
            itemsToScroll={1}
            itemsToShow={itemsToShow}
            //initialFirstItem={modalIndex}
            renderPagination={({ pages, activePage, onClick }) => {
              return (
                <Grid container direction="row" justifyContent="center">
                  <Grid
                    item
                    style={{
                      backgroundColor: "#FFF",
                      position: "absolute",
                      zIndex: 3,
                      marginTop: -70,
                      borderRadius: 7,
                    }}
                  >
                    {pages.map((page) => {
                      const isActivePage = activePage === page;
                      return (
                        <IconButton key={page} onClick={() => onClick(page)}>
                          {isActivePage ? (
                            <Adjust color="primary" sx={{ fontSize: 20 }} />
                          ) : (
                            <Circle color="primary" sx={{ fontSize: 15 }} />
                          )}
                        </IconButton>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            }}
            renderArrow={({ type, onClick }) => (
              <>
                {type === "PREV" ? (
                  <div onClick={onClick} className={classes.arrowsBoxLeft}>
                    <IconButton className={classes.arrowsLeft}>
                      <KeyboardArrowLeft sx={{ fontSize: 50 }} />
                    </IconButton>
                  </div>
                ) : (
                  <div onClick={onClick} className={classes.arrowsBoxRight}>
                    <IconButton className={classes.arrowsRight}>
                      <KeyboardArrowRight sx={{ fontSize: 50 }} />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          >
            {dataRifa?.data?.ticket?.img1 && (
              <div>
                <img src={dataRifa?.data?.ticket?.img1} width="100%" />
              </div>
            )}

            {dataRifa?.data?.ticket?.img2 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img2} width="100%" />
              </div>
            )}
            {dataRifa?.data?.ticket?.img3 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img3} width="100%" />
              </div>
            )}
            {dataRifa?.data?.ticket?.img4 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img4} width="100%" />
              </div>
            )}
            {dataRifa?.data?.ticket?.img5 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img5} width="100%" />
              </div>
            )}
            {dataRifa?.data?.ticket?.img6 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img6} width="100%" />
              </div>
            )}
            {!dataRifa?.data?.ticket?.img1 &&
              !dataRifa?.data?.ticket?.img2 &&
              !dataRifa?.data?.ticket?.img3 &&
              !dataRifa?.data?.ticket?.img4 &&
              !dataRifa?.data?.ticket?.img5 &&
              !dataRifa?.data?.ticket?.img6 && (
                <div style={{}}>
                  <img src={noImgage} width="100%" />
                </div>
              )}
          </ReactElasticCarousel>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ mt: -5, zIndex: 2, position: "relative", pl: 2, pr: 2 }}
      >
        <Grid item xs={12} sm={8} md={5}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs="12">
              <Card elevation={20}>
                <CardContent
                  sx={{
                    paddingTop: 1.5,
                  }}
                >
                  {/* 
                  {JSON.stringify(dataRifa)} */}
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    {dataRifa?.data?.ticket?.title}
                  </Typography>
                  <Divider />
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 18,
                              pt: 1,
                              mr: 1,
                            }}
                          >
                            Número de bilhetes:
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          sx={{
                            mt: -1.4,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 900,
                              fontSize: 35,
                              color: theme.palette.primary.main,
                            }}
                          >
                            <NumericFormat
                              value={
                                dataRifa?.data?.ticket?.initial_total_tickets
                              }
                              decimalScale={2}
                              decimalSeparator=","
                              displayType="text"
                              thousandSeparator="."
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 18,
                              pt: 1,
                              mr: 1,
                            }}
                          >
                            Cada bilhete por apenas
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              fontSize: 15,
                              pt: 1,
                              color: theme.palette.primary.main,
                            }}
                          >
                            R$
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            mt: -1.4,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 900,
                              fontSize: 35,
                              color: theme.palette.primary.main,
                            }}
                          >
                            <NumericFormat
                              value={dataRifa?.data?.ticket?.price}
                              decimalScale={2}
                              decimalSeparator=","
                              displayType="text"
                              fixedDecimalScale
                              thousandSeparator="."
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {dataRifa?.data?.ticket?.tickets_award.length >= 1 && (
              <Grid item xs="12">
                <Card elevation={20}>
                  <CardHeader title="Bilhetes Premiados" />
                  <CardContent>
                    <Grid
                      container
                      xs={12}
                      style={{
                        maxHeight: 200,
                        overflowY: "auto",
                      }}
                    >
                      {dataRifa?.data?.ticket?.tickets_award.map(
                        (item, index) => (
                          <Grid
                            item
                            xs={12}
                            key={index}
                            style={{
                              display: "flex",
                              gap: 8,
                              marginBottom: 10,
                            }}
                          >
                            <Grid
                              item
                              style={{
                                background: theme.palette.primary.main,
                                fontWeight: 900,
                                color: "white",
                                padding: 5,
                                borderRadius: 5,
                              }}
                            >
                              <NumericFormat
                                value={item.number}
                                decimalScale={2}
                                decimalSeparator=","
                                displayType="text"
                                thousandSeparator="."
                              />
                            </Grid>
                            <Grid
                              item
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              {item.description || "Nenhuma descrição"}
                            </Grid>
                            <Divider />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item xs="12">
              <Card elevation={20}>
                <CardHeader title="Descrição" />
                <CardContent>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(dataRifa?.data?.ticket?.desc),
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs="12">
              <Card elevation={20}>
                <CardHeader title="Organizador" />
                <CardContent>
                  <Grid container direction="row">
                    <Grid item xs={12} display="flex">
                      <Grid item>
                        <Avatar
                          alt={dataRifa?.data?.user?.name}
                          src={dataRifa?.data?.user?.image}
                          sx={{
                            width: 56,
                            height: 56,
                            border: `3px solid ${theme.palette.primary.main}`,
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        style={{ paddingLeft: 10 }}
                        gap={1}
                      >
                        <Grid item xs={12} display="flex">
                          <Typography
                            variant="div"
                            style={{
                              fontSize: 20,
                              fontWeight: 600,
                              color: theme.palette.primary.main,
                            }}
                          >
                            {dataRifa?.data?.user?.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexWrap="wrap"
                          gap={1}
                        >
                          {dataRifa?.data?.user?.networks.map((item, index) => (
                            <NetworksButton type={item.type} url={item.url} />
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer theme={theme} />
      {buyTicket && (
        <Grid
          container
          direction="row"
          sx={{
            height: 350,
          }}
        ></Grid>
      )}

      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ mt: -3, zIndex: 4, position: "fixed", bottom: 0 }}
      >
        <Grid item xs={12} sm={8} md={5}>
          <Card
            elevation={20}
            sx={{
              backdropFilter: "blur(10px)",
              background: "#ffffffc9",
            }}
          >
            <CardContent>
              {buyTicket ? (
                <>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    className="fadeIn"
                  >
                    <Grid item xs="12">
                      <Grid
                        container
                        sx={{
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          marginTop: -4,
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => setBuyTicket(false)}
                        >
                          <Close />
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs="3">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() =>
                          updateTicketsNumber("ADD", 1, dataForm.amount)
                        }
                      >
                        <span className="MuiButton-label-primary">+ 01</span>
                      </Button>
                    </Grid>
                    <Grid item xs="3">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() =>
                          updateTicketsNumber("ADD", 10, dataForm.amount)
                        }
                      >
                        {" "}
                        <span className="MuiButton-label-primary">+ 10 </span>
                      </Button>
                    </Grid>
                    <Grid item xs="3">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() =>
                          updateTicketsNumber("ADD", 100, dataForm.amount)
                        }
                      >
                        <span className="MuiButton-label-primary">+ 100 </span>
                      </Button>
                    </Grid>
                    <Grid item xs="3">
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() =>
                          updateTicketsNumber("ADD", 250, dataForm.amount)
                        }
                      >
                        <span className="MuiButton-label-primary">+ 250 </span>
                      </Button>
                    </Grid>
                    <Grid item xs="12" className="numberButtonsHidden">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <TextFieldAmount
                          id="input-with-sx"
                          onChange={handleAmountChange}
                          type="number"
                          fullWidth
                          label="Quantidade"
                          value={dataForm?.amount}
                          size="medium"
                          variant="outlined"
                          inputProps={{
                            style: {
                              textAlign: "center",
                              WebkitAppearance: "none",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Button
                                variant="contained"
                                onClick={() =>
                                  updateTicketsNumber("ADD", 1, dataForm.amount)
                                }
                              >
                                <Add
                                  sx={{
                                    color: "white",
                                    mr: 0.5,
                                    ml: 0.5,
                                    my: 0.5,
                                  }}
                                />
                              </Button>
                            ),
                            startAdornment: (
                              <Button
                                variant="contained"
                                onClick={() =>
                                  updateTicketsNumber(
                                    "REMOVE",
                                    1,
                                    dataForm.amount
                                  )
                                }
                              >
                                <Remove
                                  sx={{
                                    color: "white",
                                    mr: 0.5,
                                    ml: 0.5,
                                    my: 0.5,
                                  }}
                                />
                              </Button>
                            ),
                            style: {
                              textAlign: "center",
                            },
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs="12"
                      sx={{
                        mb: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "2px dashed",
                          borderColor: "divider",
                          position: "relative",
                          p: 1,
                          borderRadius: 1,
                          "& svg": {
                            m: 1,
                          },
                          "& hr": {
                            mx: 0.5,
                          },
                        }}
                      >
                        <Grid
                          item
                          xs="6"
                          sx={{
                            pl: 1,
                          }}
                        >
                          <Grid item xs="12">
                            <Typography
                              sx={{
                                fontWeight: 900,
                                fontSize: 18,
                              }}
                            >
                              Bilhete
                            </Typography>
                          </Grid>
                          <Grid container direction="row">
                            <Grid item>
                              <Typography
                                sx={{
                                  fontWeight: 900,
                                  fontSize: 15,
                                  pt: 1,
                                }}
                              >
                                R$
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontWeight: 900,
                                  fontSize: 35,
                                }}
                              >
                                <NumericFormat
                                  value={dataForm?.unitValue}
                                  decimalScale={2}
                                  decimalSeparator=","
                                  displayType="text"
                                  fixedDecimalScale
                                  thousandSeparator="."
                                />
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontWeight: 900,
                                  fontSize: 16,
                                  pt: 2.2,
                                }}
                              >
                                /cada
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid
                          item
                          xs="6"
                          sx={{
                            pl: 1,
                          }}
                        >
                          <Grid item xs="12">
                            <Typography
                              sx={{
                                fontWeight: 900,
                                fontSize: 18,
                              }}
                            >
                              Total
                            </Typography>
                          </Grid>
                          <Grid item xs="12">
                            <Grid container direction="row">
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontWeight: 900,
                                    fontSize: 15,
                                    pt: 1,
                                  }}
                                >
                                  R$
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontWeight: 900,
                                    fontSize: 35,
                                  }}
                                >
                                  <NumericFormat
                                    value={dataForm?.value}
                                    decimalScale={2}
                                    decimalSeparator=","
                                    displayType="text"
                                    fixedDecimalScale
                                    thousandSeparator="."
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs="12">
                    <Button
                      variant="contained"
                      fullWidth
                      disabled={isDisabledButton}
                      size="large"
                      onClick={() => setModalCheckout(true)}
                      startIcon={<ConfirmationNumber />}
                    >
                      {dataForm?.amount >= 2 && (
                        <span className="MuiButton-label-primary">
                          Comprar Bilhetes
                        </span>
                      )}
                      {dataForm?.amount == 1 && (
                        <span className="MuiButton-label-primary">
                          Comprar Bilhete
                        </span>
                      )}
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  xs="12"
                  sx={{
                    paddingTop: 1,
                  }}
                >
                  <div class="shineBtn online2">
                    <Button
                      variant="contained"
                      fullWidth
                      size="large"
                      onClick={() => setModalsetBuyTicket(true)}
                      startIcon={<ConfirmationNumber />}
                    >
                      <span className="MuiButton-label-primary">
                        Selecionar Bilhetes
                      </span>
                    </Button>
                  </div>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Rifa;
