import logo from "./logo.svg";
import "./App.css";
import AOS from "aos";

import logoBilhete from "./assets/imgs/logo.png";
import ReactOwlCarousel from "react-owl-carousel";

function App() {
  AOS.init();
  return (
    <div className="App">
      <div id="preloader">
        <div id="loader"></div>
      </div>

      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand">
              <img src={logoBilhete} alt="Logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                {/* 
            <i className="icofont-navigation-menu ico_menu"></i> --> */}
                <span className="toggle-wrap">
                  <span className="toggle-bar"></span>
                </span>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                {/* <!-- secondery menu start --> */}
                <li className="nav-item has_dropdown">
                  <a className="nav-link" href="#">
                    Home
                  </a>
                  <span className="drp_btn">
                    <i className="icofont-rounded-down"></i>
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <a href="../01-App-Landing-Page-Defoult/index.html">
                          Home 1{" "}
                        </a>
                      </li>
                      <li>
                        <a href="../02-App-Landing-Page-Center-Hero/index.html">
                          {" "}
                          Home 2{" "}
                        </a>
                      </li>
                      <li>
                        <a href="../03-App-Landing-Page-Static-Hero/index.html">
                          Home 3
                        </a>
                      </li>
                      <li>
                        <a href="../04-App-Landing-Page-Static-Hero-Center/index.html">
                          Home 4
                        </a>
                      </li>
                      <li>
                        <a href="../05-App-Landing-Page-QR-Code-Hero/index.html">
                          Home 5
                        </a>
                      </li>
                      <li>
                        <a href="../06-App-Landing-Page-Video-Hero/index.html">
                          Home 6
                        </a>
                      </li>
                      <li>
                        <a href="../07-App-Landing-Page-Slider-Hero-Star-Animation/index.html">
                          Home 7
                        </a>
                      </li>
                      <li>
                        <a href="../08-App-Landing-Page-Slider-Hero-Green/index.html">
                          Home 8
                        </a>
                      </li>
                      <li>
                        <a href="../09-App-Landing-Page-Slider-Hero-Light-Green/index.html">
                          Home 9
                        </a>
                      </li>
                      <li>
                        <a href="../10-App-Landing-Page-Static-Hero-Center-Light-Orange-Hero/index.html">
                          Home 10
                        </a>
                      </li>
                      <li>
                        <a href="../11-App-Landing-Page-Static-Hero-Center-Blue/index.html">
                          Home 11
                        </a>
                      </li>
                      <li>
                        <a href="../12-App-Landing-Page-Center-Hero-Orange-Gradient/index.html">
                          Home 12
                        </a>
                      </li>
                      <li>
                        <a href="../13-App-Landing-Page-Slider-Hero-Circle-Animated-bg/index.html">
                          Home 13
                        </a>
                      </li>
                      <li>
                        <a href="../14-App-Landing-Page-QR-Code-Dark-Hero/index.html">
                          Home 14
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="about.html">
                    About us
                  </a>
                </li>

                {/*  <!-- secondery menu start --> */}
                <li className="nav-item has_dropdown">
                  <a className="nav-link" href="#">
                    Pages
                  </a>
                  <span className="drp_btn">
                    <i className="icofont-rounded-down"></i>
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <a href="blog-list.html">Blog List</a>
                      </li>
                      <li>
                        <a href="blog-detail.html">Single Blog</a>
                      </li>
                      <li>
                        <a href="sign-in.html">Sign In</a>
                      </li>
                      <li>
                        <a href="sign-up.html">Sign Up</a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="pricing.html">
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="reviews.html">
                    Reviews
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="blog-list.html">
                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="contact.html">
                    Contact
                  </a>
                </li>
                <li className="nav-item">
                  <div className="btn_block">
                    <a
                      className="nav-link dark_btn"
                      href="https://play.google.com/store/apps/details?id=app.bilhete&fbclid=fbclid"
                    >
                      Download
                    </a>
                    <div className="btn_bottom"></div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      {/*   <!-- Banner-Section-Start --> */}
      <section className="banner_section">
        {/*  <!-- container start --> */}
        <div className="container">
          {/* <!-- row start --> */}
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              {/* <!-- banner text --> */}
              <div className="banner_text">
                {/*  <!-- typed text --> */}
                <div className="type-wrap">
                  {/*  <!-- add static words/sentences here (i.e. text that you don't want to be removed)--> */}
                  <span id="typed" className="typed"></span>
                </div>
                {/*  <!-- h1 --> */}
                <h1>
                  Criar <span>rifas online</span> nunca foi tão fácil!
                </h1>
                {/*  <!-- p --> */}
                <p>
                  Crie, gerencie e participe de rifas digitais de forma rápida e
                  segura, tudo em um só aplicativo.
                </p>
              </div>

              {/* <!-- users --> */}
              <div className="used_app">
                <ul>
                  <li>
                    <img src="images/banavt1.png" alt="image" />
                  </li>
                  <li>
                    <img src="images/banavt2.png" alt="image" />
                  </li>
                  <li>
                    <img src="images/banavt3.png" alt="image" />
                  </li>
                  <li>
                    <a
                      href="#"
                      className="popup-youtube play-button"
                      data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&amp;mute=1"
                      data-toggle="modal"
                      data-target="#myModal"
                      title="XJj2PbenIsU"
                    >
                      <img src="fonts/play.svg" alt="img" />
                    </a>
                  </li>
                </ul>
                <h3>12M + Usuários</h3>
                <p>
                  O melhor aplicativo para criar suas rifas e<br /> sortear o
                  bilhete premiado.
                </p>
              </div>

              {/* <!-- app buttons --> */}
              <ul className="app_btn">
                <li>
                  <a href="https://play.google.com/store/apps/details?id=app.bilhete&fbclid=fbclid">
                    <img
                      className="blue_img"
                      src="images/googleplay.png"
                      alt="image"
                    />
                  </a>
                </li>
                {/*  <li>
                  <a href="#">
                    <img
                      className="blue_img"
                      src="images/appstorebtn.png"
                      alt="image"
                    />
                  </a>
                </li> */}
              </ul>
            </div>
            {/* 
        <!-- banner slides start --> */}
            <div className="col-lg-6 col-md-12">
              <div className="banner_slider">
                <div className="left_icon">
                  <img src="images/smallStar.png" alt="image" />
                </div>
                <div className="right_icon">
                  <img src="images/bigstar.png" alt="image" />
                </div>

                <ReactOwlCarousel
                  id="frmae_slider"
                  className="owl-carousel owl-theme"
                  loop
                  margin={10}
                  nav={false}
                  items={1}
                  autoplay
                  autoplayTimeout={3000}
                  autoplayHoverPause
                >
                  <div className="item">
                    <div className="slider_img">
                      <img src="images/bannerScreen.png" alt="image" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src="images/bannerScreen2.png" alt="image" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src="images/bannerScreen3.png" alt="image" />
                    </div>
                  </div>
                </ReactOwlCarousel>
                <div className="slider_frame">
                  <img src="images/iphonescren.png" alt="image" />
                </div>
              </div>
            </div>

            {/* 
        <!-- banner slides end --> */}
          </div>
          {/* <!-- row end -->
      <!-- Spin Diveder Start --> */}
          <div
            className="spinBlock"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <span className="star">
              <img src="images/smallStar.png" alt="image" />
            </span>
            <div className="spin_box" id="scrollButton">
              <span className="downsign">
                <i className="icofont-long-arrow-down"></i>
              </span>
              <div className="spin-text">
                <img src="images/12mtext.png" alt="image" />
              </div>
            </div>
            <span className="star">
              <img src="images/smallStar.png" alt="image" />
            </span>
          </div>
          {/*   <!-- Spin Diveder End --> */}
        </div>
        {/*  <!-- container end --> */}
      </section>
      {/* <!-- Banner-Section-end --> */}

      {/* <!-- Task-App-Section-Start --> */}
      <section className="row_am task_app_section">
        {/* <!-- Task Block start --> */}
        <div className="task_block">
          <div className="dotes_blue">
            <img src="images/blue_dotes.png" alt="image" />
          </div>
          {/*  <!-- row start --> */}
          <div className="row">
            <div className="col-md-6">
              {/*   <!-- task images --> */}
              <div
                className="task_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <div className="frame_img">
                  <img src="images/feature1a.png" alt="image" />
                </div>
                <div className="screen_img">
                  <img
                    className="moving_animation"
                    src="images/feature1b.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/*  <!-- task text --> */}
              <div className="task_text">
                <div
                  className="section_title white_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <span className="title_badge">Unique Features</span>
                  <span className="icon">
                    <img src="images/feature-icon1.png" alt="image" />
                  </span>
                  {/*  <!-- h2 --> */}
                  <h2>Task creation and manage lorem ipsum dollar</h2>
                  {/*  <!-- p --> */}
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and setting
                    indus orem Ipsum has been the industrys standard dummxt ever
                    since the when an own printer took a galley of type and
                    scrambled it to make. a type specimen book.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*   <!-- row end --> */}
        </div>
        {/* <!-- Task Block end -->
    <!-- Task Block start --> */}
        <div className="task_block">
          <div className="dotes_blue">
            <img src="images/blue_dotes.png" alt="image" />
          </div>
          {/* <!-- row start --> */}
          <div className="row">
            <div className="col-md-6">
              {/*  <!-- task images --> */}
              <div
                className="task_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <div className="frame_img">
                  <img src="images/feature2a.png" alt="image" />
                </div>
                <div className="screen_img">
                  <img
                    className="moving_animation"
                    src="images/feature2b.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/*  <!-- task text --> */}
              <div className="task_text">
                <div
                  className="section_title white_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <span className="title_badge">Unique Features</span>
                  <span className="icon">
                    <img src="images/feature-icon2.png" alt="image" />
                  </span>
                  {/*  <!-- h2 --> */}
                  <h2>Client comunication for best results</h2>
                  {/* <!-- p --> */}
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and setting
                    indus orem Ipsum has been the industrys standard dummxt ever
                    since the when an own printer took a galley of type and
                    scrambled it to make. a type specimen book.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- row end --> */}
        </div>
        {/*  <!-- Task Block end --> */}
        {/* <!-- Task Block start --> */}
        <div className="task_block">
          <div className="dotes_blue">
            <img src="images/blue_dotes.png" alt="image" />
          </div>
          {/* <!-- row start --> */}
          <div className="row">
            <div className="col-md-6">
              {/* <!-- task images --> */}
              <div
                className="task_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <div className="frame_img">
                  <img src="images/feature3a.png" alt="image" />
                </div>
                <div className="screen_img">
                  <img
                    className="moving_animation"
                    src="images/feature3b.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/*   <!-- task text --> */}
              <div className="task_text">
                <div
                  className="section_title white_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <span className="title_badge">Unique Features</span>
                  <span className="icon">
                    <img src="images/feature-icon3.png" alt="image" />
                  </span>
                  {/* <!-- h2 --> */}
                  <h2>Live chat with Video Call</h2>
                  {/*  <!-- p --> */}
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and setting
                    indus orem Ipsum has been the industrys standard dummxt ever
                    since the when an own printer took a galley of type and
                    scrambled it to make. a type specimen book.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*  <!-- row end --> */}
        </div>
        {/* <!-- Task Block end --> */}
      </section>
      {/* <!-- Task-App-Section-end --> */}

      {/* <!-- Page Wraper --> */}
      <div className="page_wrapper">
        {/*  <!-- About Us Start--> */}
        <section className="about_section row_am">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <span className="title_badge mb-1">About us</span>
              <h2>
                Application with the best{" "}
                <img src="images/mobileicon.png" alt="image" /> user <br />
                interface convert{" "}
                <span>
                  <img src="images/usericon.png" alt="image" /> visitor
                </span>{" "}
                into{" "}
                <span>
                  <img src="images/magnet.png" alt="image" /> leads
                </span>
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <ul
                  className="app_statstic"
                  id="counter"
                  data-aos="fade-in"
                  data-aos-duration="1500"
                >
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="17">
                          0
                        </span>
                        <span>M+</span>
                      </p>
                      <p>Download</p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="2300">
                          1500
                        </span>
                        <span>+</span>
                      </p>
                      <p>Reviews</p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="150">
                          0
                        </span>
                        <span>+</span>
                      </p>
                      <p>Countries</p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="08">
                          0{" "}
                        </span>
                        <span>M+</span>
                      </p>
                      <p>Followers</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="abtImg text-center"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/appscreen.png" alt="image" />
                </div>
              </div>
              <div className="col-lg-4">
                <p data-aos="fade-up" data-aos-duration="1500">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry lorem Ipsum has been the industrys
                  standard dummy text ever since the when an unknown printer
                  took a galley of type and scrambled it to make a type specimen
                  book.
                </p>
                <div
                  className="video_block"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img
                    className="thumbnil"
                    src="images/applicationvideothumb.png"
                    alt="image"
                  />
                  <div className="playBtn">
                    <a
                      href="#"
                      className="popup-youtube play-button play_icon"
                      data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&amp;mute=1"
                      data-toggle="modal"
                      data-target="#myModal"
                      title="XJj2PbenIsU"
                    >
                      <img src="fonts/play_white.svg" alt="img" />
                    </a>
                    <img
                      className="spin_text"
                      src="images/playvideotext.png"
                      alt="image"
                    />
                  </div>
                </div>
                <div
                  className="btn_block"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <a href="contact.html" className="btn puprple_btn ml-0">
                    START FREE TRIAL
                  </a>
                  <div className="btn_bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*   <!-- Text List flow Section Start --> */}
        <div
          className="text_list_section row_am"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="container">
            <span className="title_badge down_fix">Why choose our app</span>
          </div>
          <div className="slider_block">
            <div className="owl-carousel owl-theme" id="text_list_flow">
              <div className="item">
                <div className="text_block">
                  <span>Multiple campaigns </span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>User friendly</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Advanced analytics </span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span> Task management</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span> Event Scheduler</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span> Group Video Calls</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Live Chat</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Activity Streem</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  <!-- Text List flow Section End -->

    <!-- Service Section Start --> */}
        <section className="row_am service_section">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <span className="title_badge mb-1">Services</span>
              <h2>
                Premium <span>services</span> of <br />
                our application
              </h2>
            </div>
            <div className="row service_blocks">
              <div className="col-md-6">
                <div
                  className="service_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="service_badge">
                    <i className="icofont-tasks-alt"></i>
                    <span>Task Manage</span>
                  </div>
                  <h2>
                    <span>Task creation</span> &amp; manage <br />
                    lorem ipsum dollar
                  </h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typtting industry lorem Ipsum has been the industrys
                    standard dummy text ever since.
                  </p>
                  <ul className="listing_block">
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-ui-check"></i>
                        </span>
                      </div>
                      <div className="text">
                        <h3>Automate all task</h3>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          setting industry.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-ui-check"></i>
                        </span>
                      </div>
                      <div className="text">
                        <h3>Get notify lorem</h3>
                        <p>
                          Dummy text of the printing and typesetting industry
                          lorem Ipsum has been the.
                        </p>
                      </div>
                    </li>
                  </ul>
                  <div className="btn_block">
                    <a href="contact.html" className="btn puprple_btn ml-0">
                      Start Free Trial
                    </a>
                    <div className="btn_bottom"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/service1.png" alt="image" />
                </div>
              </div>
            </div>
            <div className="row service_blocks flex-row-reverse">
              <div className="col-md-6">
                <div
                  className="service_text right_side"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="service_badge">
                    <i className="icofont-ui-clock"></i>
                    <span>Schedule Meeting</span>
                  </div>
                  <h2>
                    <span>Manage project</span> and track lorem
                  </h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry lorem Ipsum has been the industrys
                    standard dummy.
                  </p>
                  <ul className="feature_list">
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>Lorem Ipsum is simply dummy text</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>The printing and typesetting industry lorem</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>Has been the industrys dummy</p>
                      </div>
                    </li>
                  </ul>
                  <div className="btn_block">
                    <a href="contact.html" className="btn puprple_btn ml-0">
                      Start Free Trial
                    </a>
                    <div className="btn_bottom"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/service2.png" alt="image" />
                </div>
              </div>
            </div>
            <div className="row service_blocks">
              <div className="col-md-6">
                <div
                  className="service_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="service_badge">
                    <i className="icofont-list"></i> <span>History</span>
                  </div>
                  <h2>
                    <span>Task creation</span> &amp; manage lorem ipsum dollar
                  </h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typtting industry lorem Ipsum has been the industrys
                    standard dummy text ever since.
                  </p>
                  <ul className="feature_list">
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>Lorem Ipsum is simply dummy text</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>The printing and typesetting industry lorem</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>Has been the industrys dummy</p>
                      </div>
                    </li>
                  </ul>
                  <div className="btn_block">
                    <a href="#" className="btn puprple_btn ml-0">
                      Start Free Trial
                    </a>
                    <div className="btn_bottom"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/service3.png" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Service Section End -->

    <!-- How It Work Section Start --> */}
        <section className="how_it_section white_text">
          <div
            className="how_it_inner"
            data-aos="fade-in"
            data-aos-duration="1500"
          >
            <div className="dotes_blue">
              <img src="images/blue_dotes.png" alt="image" />
            </div>
            <div className="container">
              <div
                className="section_title"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <span className="title_badge">Quick &amp; easy</span>
                <h2>How it works in 3 steps</h2>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div
                    className="steps_block step_border"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="steps">
                      <div className="icon">
                        <img src="images/howstep1.png" alt="image" />
                      </div>
                      <div className="text">
                        <h3>Download app</h3>
                        <ul className="social">
                          <li>
                            <a href="#">
                              <i className="icofont-brand-android-robot"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="icofont-brand-apple"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="icofont-brand-windows"></i>
                            </a>
                          </li>
                        </ul>
                        <p>
                          Download App. It will work for <br /> Android, Mac
                          &amp; Windows
                        </p>
                      </div>
                    </div>
                    <span className="step">01</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="steps_block step_border"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="steps">
                      <div className="icon">
                        <img src="images/howstep2.png" alt="image" />
                      </div>
                      <div className="text">
                        <h3>Create account</h3>
                        <span className="tag_text">Free for 14 days</span>
                        <p>
                          Our app is FREE for 14 days, Sign up free <br /> for
                          trial period
                        </p>
                      </div>
                    </div>
                    <span className="step">02</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="steps_block"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="steps">
                      <div className="icon">
                        <img src="images/howstep3.png" alt="image" />
                      </div>
                      <div className="text">
                        <h3>Enjoy the app</h3>
                        <span className="tag_text">
                          Read FAQs for any query
                        </span>
                        <p>
                          Enjoy our app &amp; share <br /> most amazing app
                          experience
                        </p>
                      </div>
                    </div>
                    <span className="step">03</span>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className="btn_block">
                  <a href="contact.html" className="btn puprple_btn ml-0">
                    Get started now
                  </a>
                  <div className="btn_bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="review_section row_am">
        <div className="container">
          <div className="positive_inner">
            <div className="row">
              <div className="col-md-6 sticky-top">
                <div
                  className="sidebar_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="section_title text-left">
                    <span className="title_badge">Reviews</span>
                    <h2>
                      <span>Positive reviews </span> <br />
                      of our clients
                    </h2>
                  </div>
                  <div className="google_rating">
                    <div className="star">
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                    </div>
                    <p>
                      4.5/5.0 Rated on{" "}
                      <img
                        className="img-fluid"
                        src="images/google.png"
                        alt="image"
                      />
                    </p>
                  </div>
                  <div className="user_review">
                    <p>
                      1399{" "}
                      <a href="#">
                        Total user reviews{" "}
                        <i className="icofont-arrow-right"></i>
                      </a>
                    </p>
                  </div>
                  <div className="smiley_icon">
                    <img src="images/smily.png" alt="image" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="review_side">
                  <div
                    className="review_block"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="coustomer_info">
                      <div className="avtar">
                        <img src="images/review1.png" alt="image" />
                        <div className="text">
                          <h3>Willium Joy</h3>
                          <span>Smartbrain Tech</span>
                        </div>
                      </div>
                      <div className="star">
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sapiente culpa, dolores ullam laudantium deleniti ipsa qui
                      saepe voluptatum nam pariatur? Lorem ipsum dolor sit amet
                      consectetur adipisicing elit. Neque, totam.
                    </p>
                  </div>
                  <div
                    className="review_block"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="coustomer_info">
                      <div className="avtar">
                        <img src="images/review2..png" alt="image" />
                        <div className="text">
                          <h3>John Due</h3>
                          <span>Corporate Agency</span>
                        </div>
                      </div>
                      <div className="star">
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sapiente culpa, dolores ullam laudantium deleniti ipsa qui
                      saepe voluptatum nam pariatur? Lorem ipsum dolor sit amet
                      consectetur adipisicing elit. Pariatur et, nemo distinctio
                      eum omnis quam!
                    </p>
                  </div>
                  <div
                    className="review_block"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="coustomer_info">
                      <div className="avtar">
                        <img src="images/review3..png" alt="image" />
                        <div className="text">
                          <h3>Maria</h3>
                          <span>Company Inc</span>
                        </div>
                      </div>
                      <div className="star">
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sapiente culpa, dolores ullam laudantium deleniti ipsa qui
                      saepe voluptatum nam pariatur? Lorem ipsum dolor sit amet
                      consectetur adipisicing elit. Pariatur et, nemo distinctio
                      eum omnis quam!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page_wrapper">
        <section className="row_am our_client">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <span className="title_badge">Our clients</span>
              <h2>
                <span className="d-block">3500+ companies</span>
                using our applicaion
              </h2>
            </div>
            <ul className="client_list">
              <li>
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/paypal.png" alt="image" />
                </div>
              </li>
              <li>
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/spoty.png" alt="image" />
                </div>
              </li>
              <li>
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/shopboat.png" alt="image" />
                </div>
              </li>
              <li>
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/slack.png" alt="image" />
                </div>
              </li>
              <li>
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/envato.png" alt="image" />
                </div>
              </li>
              <li>
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/jquery.png" alt="image" />
                </div>
              </li>
              <li>
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/woocommerce.png" alt="image" />
                </div>
              </li>
              <li>
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/themeforest.png" alt="image" />
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section
          className="row_am pricing_section white_text"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="pricing_inner">
            <div className="container">
              <div className="dotes_blue">
                <img src="images/blue_dotes.png" alt="image" />
              </div>
              <div
                className="section_title"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="300"
              >
                <span className="title_badge">Pricing</span>
                <h2>Choose your plan</h2>
              </div>

              <ul
                className="nav nav-tabs"
                id="myTab"
                role="tablist"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="monthly-tab"
                    data-toggle="tab"
                    data-target="#monthly"
                    type="button"
                    role="tab"
                    aria-controls="monthly"
                    aria-selected="true"
                  >
                    Monthly
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="yearly-tab"
                    data-toggle="tab"
                    data-target="#yearly"
                    type="button"
                    role="tab"
                    aria-controls="yearly"
                    aria-selected="false"
                  >
                    Yearly
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="monthly"
                  role="tabpanel"
                  aria-labelledby="monthly-tab"
                >
                  <div className="pricing_pannel">
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          className="pannel_block"
                          data-aos="fade-up"
                          data-aos-duration="1500"
                        >
                          <div className="heading">
                            <h3>Free</h3>
                            <span>For single users</span>
                          </div>
                          <div className="pricing">
                            <h3>
                              $15 <span>/month</span>
                            </h3>
                          </div>
                          <ul className="features">
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Up to 15 GB cloud storage</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>30 day chat history</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>Data security</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>5 People access</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>24/7 Support</p>
                            </li>
                          </ul>
                          <div className="btn_block">
                            <a
                              href="contact.html"
                              className="btn puprple_btn ml-0"
                            >
                              Choose Plan
                            </a>
                            <div className="btn_bottom"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="pannel_block highlited_block"
                          data-aos="fade-up"
                          data-aos-duration="1500"
                        >
                          <div className="heading">
                            <h3>Premium</h3>
                            <span>For agencies</span>
                            <span className="offer">Save 50%</span>
                          </div>
                          <div className="pricing">
                            <h3>
                              $45 <span>/month</span>
                            </h3>
                          </div>
                          <ul className="features">
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Up to 15 GB cloud storage</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>30 day chat history</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Data security</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>5 People access</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>24/7 Support</p>
                            </li>
                          </ul>
                          <div className="btn_block white_btn">
                            <a
                              href="contact.html"
                              className="btn puprple_btn ml-0"
                            >
                              Choose Plan
                            </a>
                            <div className="btn_bottom"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="pannel_block"
                          data-aos="fade-up"
                          data-aos-duration="1500"
                        >
                          <div className="heading">
                            <h3>Standard</h3>
                            <span>For team</span>
                          </div>
                          <div className="pricing">
                            <h3>
                              $30 <span>/month</span>
                            </h3>
                          </div>
                          <ul className="features">
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Up to 15 GB cloud storage</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>30 day chat history</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Data security</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>5 People access</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>24/7 Support</p>
                            </li>
                          </ul>
                          <div className="btn_block">
                            <a
                              href="contact.html"
                              className="btn puprple_btn ml-0"
                            >
                              Choose Plan
                            </a>
                            <div className="btn_bottom"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="yearly"
                  role="tabpanel"
                  aria-labelledby="yearly-tab"
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="pannel_block">
                        <div className="heading">
                          <h3>Free</h3>
                          <span>For single users</span>
                        </div>
                        <div className="pricing">
                          <h3>
                            $99 <span>/month</span>
                          </h3>
                        </div>
                        <ul className="features">
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Up to 15 GB cloud storage</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>30 day chat history</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>Data security</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>5 People access</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>24/7 Support</p>
                          </li>
                        </ul>
                        <a href="contact.html" className="btn puprple_btn ml-0">
                          Choose Plan
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pannel_block highlited_block">
                        <div className="heading">
                          <h3>Premium</h3>
                          <span>For agencies</span>
                          <span className="offer">Save 50%</span>
                        </div>
                        <div className="pricing">
                          <h3>
                            $399 <span>/month</span>
                          </h3>
                        </div>
                        <ul className="features">
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Up to 15 GB cloud storage</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>30 day chat history</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Data security</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>5 People access</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>24/7 Support</p>
                          </li>
                        </ul>
                        <div className="btn_block white_btn">
                          <a
                            href="contact.html"
                            className="btn puprple_btn ml-0"
                          >
                            Choose Plan
                          </a>
                          <div className="btn_bottom"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pannel_block">
                        <div className="heading">
                          <h3>Standard</h3>
                          <span>For team</span>
                        </div>
                        <div className="pricing">
                          <h3>
                            $299 <span>/month</span>
                          </h3>
                        </div>
                        <ul className="features">
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Up to 15 GB cloud storage</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>30 day chat history</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Data security</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>5 People access</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>24/7 Support</p>
                          </li>
                        </ul>
                        <a href="contact.html" className="btn puprple_btn ml-0">
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <p
                  className="contact_text text-center"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  Not sure what to choose ? <a href="#">contact us</a> for
                  custom packages
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="row_am interface_section">
          <div className="container-fluid">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="300"
            >
              <span className="title_badge">App screens</span>
              <h2>
                Userfriendly <span>interface</span> design
              </h2>
            </div>

            <div
              className="screen_slider"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div id="screen_slider" className="owl-carousel owl-theme">
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn1.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn2.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn3.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn4.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn5.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn2.png" alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="text_list_section row_am downaload_section"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="container">
            <div className="yellow_dotes">
              <img src="images/yellow_dotes.png" alt="image" />
            </div>
            <div className="center_screen">
              <div className="img">
                <img src="images/downloadScreen.png" alt="image" />
              </div>

              <ul className="app_btn">
                <li>
                  <a href="#">
                    <img
                      className="blue_img"
                      src="images/googleplay.png"
                      alt="image"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      className="blue_img"
                      src="images/appstorebtn.png"
                      alt="image"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="background_slider">
            <div className="dowanload_slider">
              <div className="downlist">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </div>
            </div>
            <div className="slider_block">
              <div
                className="owl-carousel owl-theme"
                id="text_list_flow_download"
              >
                <div className="item">
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="dowanload_slider">
              <div className="downlist">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="blog_section row_am">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <span className="title_badge">Lates updates</span>
              <h2>
                Our latest <span>blog post</span>
              </h2>
            </div>
            <div className="blog_listing">
              <div
                className="blog_post"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <a href="#" className="img">
                  <img src="images/blog1.png" alt="image" />
                </a>
                <div className="text">
                  <ul className="blog_info">
                    <li>May 12, 2023</li>
                    <li>Mobile app</li>
                    <li>5 Comments</li>
                  </ul>
                  <h3>
                    <a href="#">
                      Effective ways to monetize mobile apps for better
                      perfomance
                    </a>
                  </h3>
                  <div className="tag_more">
                    <span className="tag">Mobile app</span>
                    <a href="#">
                      Read more <i className="icofont-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="blog_post"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <a href="#" className="img">
                  <img src="images/blog2.png" alt="image" />
                </a>
                <div className="text">
                  <ul className="blog_info">
                    <li>May 12, 2023</li>
                    <li>Mobile app</li>
                    <li>5 Comments</li>
                  </ul>
                  <h3>
                    <a href="#">
                      Why you our app, top 5 reason to choose our app
                    </a>
                  </h3>
                  <div className="tag_more">
                    <span className="tag">User experience</span>
                    <a href="#">
                      Read more <i className="icofont-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer
          className="white_text"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="logo_side">
                  <div className="logo">
                    <a href="#">
                      <img src="images/ft_logo.png" alt="Logo" />
                    </a>
                  </div>
                  <div className="news_letter">
                    <h3>Subscribe newsletter</h3>
                    <p>Be the first to recieve all latest post in your inbox</p>
                    <form>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                        <button className="btn">
                          <i className="icofont-paper-plane"></i>
                        </button>
                      </div>
                      <p className="note">
                        By clicking send link you agree to receive message.
                      </p>
                    </form>
                  </div>
                  <ul className="contact_info">
                    <li>
                      <a href="mailto:">support@example.com</a>
                    </li>
                    <li>
                      <a href="tel: ">+1-900-123 4567</a>
                    </li>
                  </ul>
                  <ul className="social_media">
                    <li>
                      <a href="#">
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="icofont-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="icofont-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="download_side">
                  <h3>Download app</h3>
                  <ul className="app_btn">
                    <li>
                      <a href="#">
                        <img
                          className="blue_img"
                          src="images/googleplay.png"
                          alt="image"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          className="blue_img"
                          src="images/appstorebtn.png"
                          alt="image"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_bottom">
            <div className="container">
              <div className="ft_inner">
                <div className="copy_text">
                  <p>© Copyrights 2023. All rights reserved.</p>
                </div>
                <ul className="links">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a href="about.html">About us</a>
                  </li>
                  <li>
                    <a href="pricing.html">Pricing</a>
                  </li>
                  <li>
                    <a href="blog-list.html">Blog</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact us</a>
                  </li>
                </ul>
                <div className="design_by">
                  <p>
                    Crafted by{" "}
                    <a
                      href="https://themeforest.net/user/kalanidhithemes"
                      target="blank"
                    >
                      Kalanidhi Themes
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="go_top" id="Gotop">
          <span>
            <i className="icofont-arrow-up"></i>
          </span>
        </div>

        <div
          className="modal fade youtube-video"
          id="myModal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <button
                id="close-video"
                type="button"
                className="button btn btn-default text-right"
                data-dismiss="modal"
              >
                <i className="icofont-close-line-circled"></i>
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe
                    id="youtubevideo"
                    width="640"
                    height="360"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
