import React, { useState, useEffect } from "react";
import { Typography } from "../Typography";

const CountdownTimer = ({ datestamp }) => {
  const calculateTimeLeft = () => {
    const targetTime = new Date(datestamp).getTime() + 60 * 60 * 1000; // 1 hora adicionada ao datestamp
    const currentTime = new Date().getTime();
    const difference = targetTime - currentTime;

    if (difference <= 0) {
      return { expired: true };
    }

    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
      expired: false,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      {timeLeft.expired ? (
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: 25,
            color: "red",
          }}
        >
          EXPIRADO
        </Typography>
      ) : (
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: 30,
          }}
        >
          {timeLeft.minutes}:{timeLeft.seconds}
        </Typography>
      )}
    </div>
  );
};

export default CountdownTimer;
