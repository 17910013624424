import { Card, CardContent, Grid } from "@mui/material";
import * as React from "react";
import { getCheckoutStatusFromAPI } from "../../hooks/Checkout/useCheckout";
import { Typography } from "../Typography";
import scrollToTop from "../../utils/scrollToTop";

export default function CheckerStatus({ uuid, data, onPayment }) {
  const [dataCheckoutStatus, setDataCheckoutStatus] = React.useState(data);
  const intervalIdRef = React.useRef(null); // useRef para armazenar o intervalId

  const getCheckoutStatus = React.useCallback(() => {
    getCheckoutStatusFromAPI(uuid).then((res) => {
      if (JSON.stringify(res) !== JSON.stringify(dataCheckoutStatus)) {
        setDataCheckoutStatus(res);
      }
      if (res.status === 2) {
        onPayment();
        clearInterval(intervalIdRef.current);
        scrollToTop(); // Para o setInterval
      }
    });
  }, [dataCheckoutStatus]);

  React.useEffect(() => {
    // Configura o intervalo para executar a função a cada 5 segundos
    intervalIdRef.current = setInterval(getCheckoutStatus, 5000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalIdRef.current);
  }, [getCheckoutStatus]);

  React.useEffect(() => {
    getCheckoutStatus();
  }, []);

  return (
    <Grid
      item
      xs="12"
      sx={{
        p: 0,
        m: 0,
        position: "sticky",
        top: "78px",
        zIndex: 1,
      }}
    >
      <Card
        elevation={2}
        sx={{
          p: 0,
          m: 0,
          fontWeight: "900",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ m: 0, p: 1 }}
        >
          {dataCheckoutStatus.status == 1 && (
            <>
              <span class="status idle"></span>{" "}
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 800,
                  mt: 0.5,
                }}
              >
                Aguardando Pagamento
              </Typography>
            </>
          )}
          {dataCheckoutStatus.status == 2 && (
            <>
              <span class="status online"></span>{" "}
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 800,
                  mt: 0.5,
                }}
              >
                Pagamento Realizado
              </Typography>
            </>
          )}
        </Grid>
      </Card>
    </Grid>
  );
}
