import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import * as Yup from "yup";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import {
  Close,
  ConfirmationNumber,
  Instagram,
  WhatsApp,
} from "@mui/icons-material";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { Typography } from "../../../Typography";
import { Button } from "../../../Button";
import { useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

export default function MenuList({ onClose }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [isDisabledButton, setDisabledButton] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const formik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: Yup.object({
      number: Yup.string().required("Campo obrigatório"),
    }),
    onSubmit: (values) => {
      navigate("/transactions/" + values.number);
      onClose();
    },
  });

  const searchNumbers = () => {
    formik.submitForm();
  };

  return (
    <List
      sx={{ width: "100%" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          sx={{
            backgroundColor: "transparent",
          }}
          component="div"
          id="nested-list-subheader"
        >
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item xs={11}>
              Menu - Bilhete.app
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={onClose}>
                <Close color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </ListSubheader>
      }
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <ConfirmationNumber color="primary" />
        </ListItemIcon>
        <ListItemText primary="Meus Bilhetes" />

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <List
            sx={{
              pl: 4,
              pr: 2,
              mb: 4,
            }}
            component="div"
            disablePadding
          >
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: 20,
                  }}
                >
                  Digite o seu número de celular:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MuiPhoneNumber
                  id="number"
                  name="number"
                  value={formik.values.number}
                  helperText={formik.touched.number && formik.errors.number}
                  autoComplete="off"
                  onChange={(value) => formik.setFieldValue("number", value)}
                  error={formik.touched.number && Boolean(formik.errors.number)}
                  fullWidth
                  defaultCountry={"br"}
                  variant="outlined"
                  style={{
                    borderRadius: 50,
                  }}
                />
              </Grid>
              <Grid
                item
                xs="12"
                sx={{
                  pt: 2,
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  disabled={isDisabledButton}
                  size="small"
                  onClick={() => searchNumbers()}
                  startIcon={<ConfirmationNumber />}
                >
                  <span className="MuiButton-label-primary">
                    Verificar meus bilhetes
                  </span>
                </Button>
              </Grid>
            </Grid>
          </List>
        </Box>
      </Collapse>

      <Divider />

      <Link to="https://instagram.com/bilhete.app" target="_blank">
        <ListItemButton>
          <ListItemIcon>
            <Instagram color="primary" />
          </ListItemIcon>
          <ListItemText primary="Instagram" />
        </ListItemButton>
      </Link>
      <Link
        to={`https://api.whatsapp.com/send/?phone=55115511917742435`}
        target="_blank"
      >
        <ListItemButton>
          <ListItemIcon>
            <WhatsApp color="primary" />
          </ListItemIcon>
          <ListItemText primary="Suporte" />
        </ListItemButton>
      </Link>
    </List>
  );
}
