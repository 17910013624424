import { Grid, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import MuiPhoneNumber from "material-ui-phone-number";
import * as React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StepOne({ handleChange, formData }) {
  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Typography>Digite o seu número de celular:</Typography>
      </Grid>
      <Grid item xs={12}>
        <MuiPhoneNumber
          onChange={handleChange}
          fullWidth
          defaultCountry={"br"}
          value={formData.custumer_phone}
          variant="outlined"
          style={{
            borderRadius: 50,
          }}
        />
      </Grid>
    </Grid>
  );
}
