import { Grid } from "@mui/material";
import { Typography } from "../Typography";

export const Footer = ({ children, theme, ...props }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      display="flex"
      sx={{
        height: 200,
        paddingTop: 5,
        justifyContent: "center !important",
      }}
    >
      <Typography variant="h6">
        Site criado com 💙 por{" "}
        <a
          target="_blank"
          style={{
            color: theme.palette.primary.main,
          }}
          href="https://bilhete.app"
          rel="noreferrer"
        >
          <span>Bilhete.app</span>
        </a>
      </Typography>
    </Grid>
  );
};
