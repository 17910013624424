import {
  ArrowCircleRight,
  ArrowLeft,
  ArrowRight,
  Search,
} from "@mui/icons-material";
import {
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import { Card as CardCustom } from "../../components/Card";
import { CardHeader } from "../../components/CardHeader";
import { Footer } from "../../components/Footer";
import HeaderMenu from "../../components/HeaderMenu";
import { Typography } from "../../components/Typography";
import { getTransactionsFromAPI } from "../../hooks/Checkout/useCheckout";

export default function Transactions() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [dataCheckout, setDataCheckout] = React.useState([]);
  const [pagination, setPagination] = React.useState({});
  const theme = useTheme();

  const getTransactions = () => {
    setLoading(true);
    getTransactionsFromAPI(uuid, page, pageSize, search)
      .then((res) => {
        setDataCheckout(res.data.data);
        setPagination(res.data.pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      setPage(newPage);
    }
  };

  React.useEffect(() => {
    getTransactions();
  }, []);

  return (
    <Grid container direction="row" justifyContent="center">
      <HeaderMenu />
      <Grid item xs={12} sm={8} md={5} sx={{ p: 2 }}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs="12">
            <Card
              variant="outlined"
              elevation={20}
              sx={{
                p: 0,
              }}
            >
              <Grid
                container
                direction="row"
                spacing={1}
                sx={{
                  pl: 2,
                  pr: 2,
                  pb: 1,
                }}
              >
                <Grid item xs="12">
                  <TextField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    id="search"
                    label="Buscar"
                    name="search"
                    autoComplete="off"
                    autoFocus
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={() => getTransactions()}>
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        fontSize: "16px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {dataCheckout?.map((item, index) => (
            <Grid
              item
              xs="12"
              key={index}
              sx={{
                cursor: "pointer",
              }}
            >
              <CardCustom
                onClick={() => navigate("/checkout/" + item.id)}
                variant="outlined"
                elevation={20}
                sx={{
                  p: 0,
                }}
              >
                <CardHeader title={item.ticket.title} />
                <CardContent sx={{ mt: -2 }}>
                  <Grid
                    container
                    display="flex"
                    flexWrap="nowrap"
                    gap={2}
                    alignItems="center"
                  >
                    <Grid item>
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          backgroundImage: `url("${item.ticket.img1}")`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: 8,
                        }}
                      ></div>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="h6"
                        style={{
                          lineHeight: 1.1,
                          fontWeight: 400,
                          fontSize: 20,
                        }}
                      >
                        Bilhetes:{" "}
                        <NumericFormat
                          value={item.amount}
                          decimalScale={2}
                          decimalSeparator=","
                          displayType="text"
                          thousandSeparator="."
                        />
                      </Typography>

                      <Typography
                        variant="h6"
                        style={{
                          lineHeight: 1.1,
                          fontWeight: 400,
                          fontSize: 20,
                        }}
                      >
                        Preço: R${" "}
                        <NumericFormat
                          value={item.value}
                          style={{
                            fontWeight: 900,
                          }}
                          decimalScale={2}
                          decimalSeparator=","
                          displayType="text"
                          fixedDecimalScale
                          thousandSeparator="."
                        />
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          lineHeight: 1.1,
                          fontWeight: 400,
                          fontSize: 20,
                        }}
                      >
                        Status:{" "}
                        {item.status == "2" ? (
                          <Chip color="success" size="small" label="Pago" />
                        ) : (
                          <Chip
                            color="error"
                            size="small"
                            label="Pagamento não realizado"
                          />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ArrowCircleRight
                        color="secondary"
                        style={{ alignSelf: "flex-start" }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </CardCustom>
            </Grid>
          ))}

          <Grid item xs="12">
            <Card
              variant="outlined"
              elevation={20}
              sx={{
                p: 0,
              }}
            >
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <IconButton
                      disabled={page <= 1}
                      onClick={() => handlePageChange(page - 1)}
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      Página {pagination.currentPage} de {pagination.totalPages}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={page >= pagination.totalPages}
                      onClick={() => handlePageChange(page + 1)}
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Footer theme={theme} />
      </Grid>
    </Grid>
  );
}
