import * as React from "react";
import * as Yup from "yup";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import { Edit, Mail, People, PeopleAlt, Person } from "@mui/icons-material";
import { TextField } from "../../../TextField";
import { Button } from "../../../Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StepTwo({ handleChange, formData, formik, setStep }) {
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <TextField
            margin="normal"
            variant="outlined"
            required
            fullWidth
            id="custumer_name"
            label="Nome Completo"
            name="custumer_name"
            autoComplete="off"
            autoFocus
            value={formik.values.custumer_name}
            onChange={formik.handleChange}
            error={
              formik.touched.custumer_name &&
              Boolean(formik.errors.custumer_name)
            }
            helperText={
              formik.touched.custumer_name && formik.errors.custumer_name
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            variant="outlined"
            required
            fullWidth
            id="custumer_email"
            label="E-mail"
            name="custumer_email"
            autoComplete="off"
            autoFocus
            value={formik.values.custumer_email}
            onChange={formik.handleChange}
            error={
              formik.touched.custumer_email &&
              Boolean(formik.errors.custumer_email)
            }
            helperText={
              formik.touched.custumer_email && formik.errors.custumer_email
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
              style: {
                fontSize: "16px", // Definindo o tamanho da fonte corretamente
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <MuiPhoneNumber
            fullWidth
            defaultCountry={"br"}
            name="custumer_phone"
            id="custumer_phone"
            disabled
            autoComplete="off"
            value={formik.values.custumer_phone}
            variant="outlined"
            label="Número de telefone"
            error={
              formik.touched.custumer_phone &&
              Boolean(formik.errors.custumer_phone)
            }
            helperText={
              formik.touched.custumer_phone && formik.errors.custumer_phone
            }
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <MuiPhoneNumber
            name="custumer_phone_repeat"
            id="custumer_phone_repeat"
            onChange={(value) =>
              formik.setFieldValue("custumer_phone_repeat", value)
            }
            fullWidth
            defaultCountry={"br"}
            autoComplete="off"
            value={formik.values.custumer_phone_repeat}
            variant="outlined"
            label="Confirmar número de telefone"
            error={
              formik.touched.custumer_phone_repeat &&
              Boolean(formik.errors.custumer_phone_repeat)
            }
            helperText={
              formik.touched.custumer_phone_repeat &&
              formik.errors.custumer_phone_repeat
            }
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, mb: -2 }}>
          <Button
            disabled={
              formData?.custumer_phone?.length <= 16 ||
              !formData?.custumer_phone?.length
            }
            onClick={() => setStep(1)}
            variant="outlined"
            size="small"
            fullWidth
          >
            <span className="MuiButton-label-primary">Editar telefone</span>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
